<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">
    <mat-toolbar class="sidenav_toolbar">
      <img width="150" alt="Billpocket Logo" src="../../assets/billpocket_horizontal_white.svg" style="margin: 10px" />
    </mat-toolbar>
    <mat-divider></mat-divider>

    <mat-nav-list>

      <a class="submenu-link" mat-list-item routerLink="/grupos">
        <mat-icon>people</mat-icon>Grupos
      </a>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>credit_card</mat-icon>Bines
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a class="submenu-link" mat-list-item routerLink="/bines">
            <mat-icon>credit_card</mat-icon>Bines
          </a>
          <a class="submenu-link" mat-list-item routerLink="/bines/actualizacion">
            <mat-icon>autorenew</mat-icon>Actualización de Bines
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <a class="submenu-link" mat-list-item routerLink="/afiliaciones">
        <mat-icon>person_add</mat-icon>Afiliaciones
      </a>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>people_outline</mat-icon>Distribuidores
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a class="submenu-link" mat-list-item routerLink="/distribuidores/tasas-usuarios">
            <mat-icon>trending_up</mat-icon>Tasas de usuarios
          </a>
          <a class="submenu-link" mat-list-item routerLink="/distribuidores/tasas-distribuidores">
            <mat-icon>person_add</mat-icon>Gestión de distribuidores
          </a>
          <a class="submenu-link" mat-list-item routerLink="/distribuidores/reportes">
            <mat-icon> insert_chart_outlined</mat-icon>Reportería
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <a class="submenu-link" mat-list-item routerLink="/tarjetabillpocket">
        <mat-icon>credit_card</mat-icon>Solicitudes de tarjeta
      </a>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>person</mat-icon>Usuarios
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/bonos">
            <mat-icon style="margin-right: 1rem">card_giftcard</mat-icon>Bonos
          </a>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/fondo-seguridad">
            <mat-icon style="font-size: large">rate_review</mat-icon>Fondo de seguridad
          </a>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/gestion-forma-pago">
            <mat-icon style="font-size:large">account_balance</mat-icon>Gestión forma pago
          </a>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/nivel-riesgo">
            <mat-icon style="font-size:large">warning</mat-icon>Nivel de riesgo
          </a>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/validar/curp">
            <mat-icon style="font-size:large">verified_user</mat-icon>Validar CURP
          </a>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/validar/rfc">
            <mat-icon style="font-size:large">verified_user</mat-icon>Validar RFC
          </a>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/intervalos">
            <mat-icon style="font-size:large">access_time</mat-icon>Intervalos
          </a>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/clicktopay">
            <mat-icon style="font-size:large">credit_card</mat-icon>Click to Pay
          </a>
          <a class="submenu-link-schedule" mat-list-item routerLink="/usuarios/actualizar-parametros-deposito">
            <mat-icon>edit_calendar</mat-icon><span class="text">Parámetros de depósito</span>
          </a>
          <a class="submenu-link" mat-list-item routerLink="/usuarios/renta-terminal">
            <mat-icon>mobile_friendly</mat-icon><span class="text">Renta de terminales</span>
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>credit_card</mat-icon>Tarjetas BP
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a class="submenu-link" mat-list-item routerLink="/eliminar-tarjetas">
          <mat-icon style="font-size: large">credit_card_off</mat-icon>Eliminación de tarjetas
        </a>
        <a class="submenu-link" mat-list-item routerLink="/asignar-tarjeta">
          <mat-icon style="font-size: large">credit_card</mat-icon>Asignar tarjeta
        </a>
      </mat-expansion-panel>
      <a class="submenu-link" mat-list-item routerLink="/bloquear-usuarios">
        <mat-icon>block</mat-icon>Bloquear y Desbloquear
      </a>
      <a class="submenu-link" mat-list-item routerLink="/estado-de-cuenta">
        <mat-icon>account_balance</mat-icon>Estado de Cuenta
      </a>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>groups</mat-icon>Referidos
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a class="submenu-link" mat-list-item routerLink="/referidos/usuarios-principales">
            <mat-icon>person_search</mat-icon>Usuarios Principales
          </a>
          <a class="submenu-link" mat-list-item routerLink="/referidos/consulta">
            <mat-icon>people</mat-icon>Consulta Referidos
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>attach_money</mat-icon>Cobranza MO
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a class="submenu-link" mat-list-item routerLink="/cobranza-mo/cobranza">
          <mat-icon>attach_money</mat-icon>Cobranza
        </a>
        <a class="submenu-link" mat-list-item routerLink="/cobranza-mo/historial">
          <mat-icon>history</mat-icon>Historial
        </a>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>fact_check</mat-icon>Conciliación
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a class="submenu-link" mat-list-item routerLink="/conciliacion/manual">
          <mat-icon>upgrade</mat-icon>Conciliación manual
        </a>
        <a class="submenu-link" mat-list-item routerLink="/conciliacion/reportes">
          <mat-icon>restore_page</mat-icon>Reportes de Conciliación
        </a>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>assignment</mat-icon>Reportes
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a class="submenu-link" mat-list-item routerLink="/reportes/riesgo">
          <mat-icon style="font-size:large">warning</mat-icon>Reportes de Riesgo
        </a>
      </mat-expansion-panel>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <!-- BARRA SUPERIOR -->
      <mat-toolbar-row fxLayout="row">
        <button mat-button (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <img width="100" alt="Billpocket Logo" src="../../assets/huey.png" style="margin: 15px" />
        <span>Huey</span>

        <mat-card-content style="margin-left: auto">
          <button mat-icon-button [matMenuTriggerFor]="menu" style="margin-top: 0.5rem;">
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="logOut()">
              <mat-icon>exit_to_app</mat-icon>Cerrar Sesión
            </button>
          </mat-menu>
        </mat-card-content>
      </mat-toolbar-row>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
